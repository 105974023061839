<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    authority="Congress_Management_Congress_Session"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './sessionsStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      entity: {
        congressTelephones: [],
      },
      entityLoaded: false,
      congressRoomOptions: [],
      sessionTypeOptions: [],
    }
  },
  computed: {
    congressID() {
      return this.$route.params.id
    },
    config() {
      return {
        store: storeModule,
        module: 'congress-sessions',
        endpoint: 'congress-sessions',
        route: 'congress-sessions',
        title: {
          single: this.$t('Congress Session'),
          plural: this.$t('Congress sessions'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formFields() {
      return [
        {
          id: 'congressTitle',
          type: 'hidden',
          hidden: true,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
          rules: 'min:3',
        },
        {
          id: 'description',
          type: 'text',
          label: this.$t('description'),
        },
        {
          id: 'congressRoomId',
          type: 'select',
          object: true,
          label: this.$i18n.t('congressRoomId'),
          required: true,
          options: this.congressRoomOptions,
          colSize: 3,
        },
        {
          id: 'sessionTypeId',
          type: 'select',
          object: true,
          label: this.$i18n.t('sessionTypeId'),
          required: true,
          options: this.sessionTypeOptions,
          colSize: 3,
        },
        {
          id: 'congressRoomTitle',
          type: 'text',
          label: this.$t('congressRoomTitle'),
          required: true,
          rules: 'min:3',
        },
        {
          id: 'sessionTypeTitle',
          type: 'text',
          label: this.$t('sessionTypeTitle'),
          required: true,
          rules: 'min:3',
        },
        {
          id: 'colorHex',
          type: 'text',
          label: this.$t('colorHex'),
          rules: 'min:6',
        },
        {
          id: 'startDate',
          type: 'date',
          label: this.$t('Start Date'),
          refMaxDate: 'endDate',
          required: true,
          colSize: 3,
        },
        {
          id: 'endDate',
          type: 'date',
          label: this.$t('End Date'),
          refMinDate: 'startDate',
          required: true,
          colSize: 3,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCongressRooms', this.congressID)
      .then(response => {
        this.congressRoomOptions = response.data
      })
    this.$store.dispatch('app-common/fetchCongressCategories')
      .then(response => {
        this.sessionTypeOptions = response.data
      })
  },
}
</script>
